<template>
  <section
    data-test="promo-block"
    class="p-promo container"
  >
    <h2 class="p-promo__title headline-2">
      Акции от {{ provider.provider.name }}
    </h2>
    <VLazy
      :min-height="100"
      @update:model-value="changeView"
    >
      <LazyVSwiper
        slides-per-view="auto"
        :space-between="20"
        :threshold="3"
        :css-mode="!disabledPortal"
        :centered-slides="!disabledPortal"
        :centered-slides-bounds="!disabledPortal"
        navigation
        :pagination="{ clickable: true }"
        class="relative"
        @swiper="onSwiper"
      >
        <SwiperSlide
          v-for="(promo, i) in promotions"
          :key="'promo' + i"
          class="p-promo__item relative"
          :style="{ '--bgColor': hexToRgb(promo.background_color) }"
        >
          <img
            loading="lazy"
            class="p-promo__item-img"
            :src="imgUrl(mainStore.domain + promo.image)"
            :alt="promo.title"
          >
          <div class="p-promo__item-wrap">
            <p class="p-promo__item-title fwb">
              {{ promo.title }}
            </p>
            <p
              class="p-promo__item-descr"
              v-html="promo.content"
            />
            <d-btn
              class="p-promo__item-btn"
              color="custom"
              @click="showDialogCallPartner"
            >
              {{ promo.button }}
            </d-btn>
          </div>
        </SwiperSlide>

        <div class="swiper-btn-wrap swiper-btn-wrap--prev" />
        <div class="swiper-btn-wrap" />
      </LazyVSwiper>
    </VLazy>
    <div
      v-if="!showSwiper"
      class="d-flex scroll"
    >
      <div
        v-for="(promo, i) in promotions"
        :key="'promo' + i"
        class="p-promo__item relative"
        :style="{ '--bgColor': hexToRgb(promo.background_color) }"
      >
        <img
          loading="lazy"
          class="p-promo__item-img"
          :src="imgUrl(mainStore.domain + promo.image)"
          :alt="promo.title"
        >
        <div class="p-promo__item-wrap">
          <p class="p-promo__item-title fwb">
            {{ promo.title }}
          </p>
          <div
            v-if="promo.content"
            class="p-promo__item-descr"
            v-html="promo.content"
          />
          <d-btn
            class="p-promo__item-btn"
            color="custom"
            @click="showDialogCallPartner"
          >
            {{ promo.button }}
          </d-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { hexToRgb } from '~/helpers'
import { useCities } from '~/store/cites/cities.store'

defineProps({
  promotions: {},
})

const ctx = useNuxtApp()
const mainStore = useMainStore()
const cityStore = useCities()
const provider = computed(() => mainStore.getDomainConfig)
const disabledPortal = computed(() => mainStore.disabledPortal)
const houseUrl = computed(() => cityStore.getHouseUrl)
const showSwiper = ref(false)
const changeView = () => {
  showSwiper.value = true
}
const imgUrl = (path) => {
  const url = ctx.$thumborURL
    .setImagePath(path)
    .resize(ctx.$device.isMobile ? 400 : 750, 0)
    .buildUrl()
  return url
}
const onSwiper = (swiper) => {
  swiper.activeIndex = 0
}

const showDialogCallPartner = () => {
  if (houseUrl.value) ctx.$event('callDialog', 'Получить консультацию')
  else ctx.$event('callDialogPartner', true)
}
</script>

<style scoped lang="scss">
.p-promo {
  padding-top: 40px;
  padding-bottom: 40px;
  &__title {
  }
  &__item {
    width: 100% !important;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(2, 4, 30, 0.1);
    @media (max-width: getBreakpoint(tablet)) {
      max-width: 90% !important;
    }
    &-wrap {
      background: linear-gradient(
        90deg,
        rgb(var(--bgColor)) 40%,
        rgba(var(--bgColor), 0) 100%
      );
      padding: 32px 40px;
      width: 100%;
      position: relative;
      @media (max-width: getBreakpoint(tablet)) {
        display: flex;
        flex-direction: column;
        padding: 224px 16px 16px;
        background: linear-gradient(
          0deg,
          rgb(var(--bgColor)) 50%,
          rgba(var(--bgColor), 0) 100%
        );
      }
    }

    &-img {
      position: absolute;
      right: 0;
      top: 50%;
      bottom: 0;
      transform: translateY(-50%);
      object-fit: cover;
      width: 60%;
      max-height: 140%;
      min-height: 100%;
      @media (max-width: getBreakpoint(tablet)) {
        top: 0;
        left: 0;
        transform: unset;
        width: unset;
        height: 280px;
        min-width: 100%;
        min-height: unset;
        max-height: unset;
      }
    }

    &-btn {
      background: var(--mainColor);
      color: var(--font_main);
      padding: 16px 48px;
      font-weight: 500;
      font-size: fontSize(title);
      @media (max-width: getBreakpoint(tablet)) {
        padding: 8px 32px;
        width: 100%;
        max-width: 400px;
        height: 48px;
        margin-top: auto;
      }
    }

    &-title {
      font-size: fontSize(headline-5);
      line-height: 1.4;
      margin-bottom: 8px;
      max-width: 500px;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(headline-8);
      }
    }
    &-descr {
      font-size: fontSize(headline-8);
      line-height: 1.4;
      margin-bottom: 24px;
      max-width: 500px;

      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(title);
        margin-bottom: 16px;
      }
    }
  }
  &:deep(.swiper-wrapper) {
    @media (max-width: getBreakpoint(desktop)) {
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding: 16px 16px 14px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-left: -8px;
      margin-right: -8px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  &:deep(.swiper-pagination) {
    justify-content: flex-end;
  }
  &:deep(.swiper-pagination-bullet) {
    border: 1px solid var(--mainColor);
  }
  &:deep(.swiper-pagination-bullet-active) {
    background: var(--mainColor);
  }
  &:deep(.swiper-btn-wrap) {
    background: linear-gradient(
      90deg,
      rgba(color(white), 0) 0%,
      color(white) 100%
    ) !important;
  }
}
</style>
